.wrap {
  background: #094074;
  padding: 15px;
  color: #fff;
  width: 100%;
  min-height: 130px;
}
.wrap div:nth-child(1) {
  display: flex;
  justify-content: space-between;
}
.logo {
  width: 45%;
  height: auto;
}
.wrap div:nth-child(2) {
  width: 70%;
}
.wrap h4 {
  text-align: center;
}
.inputDiv {
  width: 80%;
  margin-left: auto;
}
.email {
  padding: 5px;
  width: 75%;
  background: #c4c4c4;
  border-style: none;
  margin-right: 5px;
}
.email:focus {
  outline: none;
}
.sub {
  padding: 5px;
  width: 25%;
  border-style: none;
  border: 1px solid #fff;
  background: #094074;
  color: #fff;
  font-size: 1.2em;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.sub:hover {
  background: #fe9000;
}
.wrap p {
  width: 60%;
  text-align: right;
  font-size: 1.2em;
  line-height: 24px;
}
form div {
  display: flex;
  flex-flow: row nowrap !important;
}
@media screen and (max-width: 400px) {
  .wrap div:nth-child(1) {
    flex-direction: column;
  }
  .wrap p {
    width: 100%;
    font-size: 0.8em;
    font-weight: 800;
    text-align: left;
  }
  .email {
    font-size: 0.8em;
  }
  .sub {
    width: 40%;
    margin-right: 0;
    font-size: 0.6;
    line-height: 12px;
  }
}
@media screen and (max-width: 650px) {
  .wrap div:nth-child(1) {
    width: 100%;
  }

  .logo {
    width: 180px;
    height: 60px;
  }
  .inputDiv {
    width: 100%;
  }
  .email {
    width: 60%;
    padding: 4px;
  }
  .sub {
    width: 40%;
    font-size: 1em;
  }
  .wrap h4 {
    font-size: 1.1em;
  }
  .wrap p {
    width: 90%;
  }
}
@media screen and (max-width: 850px) {
  .inputDiv {
    width: 100%;
  }
}
