.loginWrap {
  width: 65%;
  border-radius: 15px;
  border: 1px solid #ccc;
  box-shadow: 0 0 14px #ccc;
  text-align: center;
  margin: 5% auto;
}
.logForm {
  width: 100%;
  padding: 10px;
  margin: 3% auto;
}
.inputContainer,
.remDiv {
  width: 100%;
  padding: 5px;
}
.inputContainer input {
  padding: 8px;
  width: 100%;
  background: #ccc;
  border-radius: 5px;
  border-style: none;
  margin: 5px 0;
}
.inputContainer input:focus {
  outline: none;
}
.remDiv p:nth-child(1) {
  font-size: 1.2em;
  font-weight: 400;
  margin-right: auto;
  color: #0373ac;
}
input[type="checkbox"] {
  margin-right: 14px;
}
.remDiv p:nth-child(2) {
  font-size: 1.2em;
  font-weight: 700;
  margin-left: auto;
  color: rgb(110, 1, 1);
  cursor: pointer;
}
#link{
  font-weight: bold;
  font-size: 1.8em;
  color: #02a75a;
  text-decoration: underline;
  cursor: pointer;
}
.loginOption{
  font-size: 1.2em;
  margin: 2% 0;
  font-weight: 600;
}
.loginOption span{
  color:#0377b1;
  cursor: pointer;
}
.error {
  color: #720e01 !important;
  font-size: 1.2em !important;
  font-weight: bold !important;
}
.logBtn {
  width: 100%;
  padding: 5px;
  border-style: none;
  border-radius: 10px;
  font-size: 1.3em;
  font-weight: 800;
  color: #fff;
  background: #0373ac;
  margin: 0 0 5px 0;
}
.logBtn:hover {
  background: #fff;
  border: 1px solid;
  border-color: #0373ac;
  color: #0373ac;
  transition: all 2s ease;
}
.logForm p {
  font-size: 1.2em;
  font-weight: 400;
}
.reg {
  color: rgb(110, 1, 1);
  font-weight: 700;
}
.reg:hover {
  cursor: pointer;
  text-decoration: none;
  color: #3bbafa;
}
#logos {
  width: 95%;
  height: auto;
  margin: 2% 0;
}
.loginWrap h4 {
  font-weight: 800;
  font-size: 2.1em;
  margin-bottom: 0;
  color: #fff;
  text-shadow: 2px 2px 2px #0373ac;
}
@media screen and (max-width: 378px) {
  .loginWrap h4 {
    font-size: 1.8em;
  }
  .remDiv p:nth-child(2),
  .remDiv p:nth-child(2) {
    font-size: 0.8em;
    font-weight: 100;
  }
}
@media screen and (max-width: 860px) {
  .remDiv p:nth-child(2),
  .remDiv p:nth-child(2),
  .logForm p {
    font-size: 1em;
    font-weight: 600;
  }
}
@media screen and (min-width: 1024px) {
  .loginWrap {
    width: 40%;
  }
}
